import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  categoryNameErrors () {
    const errors = []
    if (!this.$v.category.name.$dirty) return errors
    !this.$v.category.name.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  fatherCategoryErrors () {
    const errors = []
    if (!this.$v.category.categoryId.$dirty) return errors
    !this.$v.category.categoryId.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  toIdErrors () {
    const errors = []
    if (!this.$v.toId.$dirty) return errors
    !this.$v.toId.required && errors.push(REQUIRED_FIELD)
    return errors
  }
}